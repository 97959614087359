import { animated } from '@react-spring/web';
import React, { useMemo } from 'react';
import { BaseButton, ButtonProps } from '../BaseButton';

type Variant = 'default' | 'dark' | 'light';
interface SecondaryButtonProps extends ButtonProps {
  variant: Variant;
  isDanger?: boolean;
}

const secondaryButtonGradient: Record<Variant, Record<string, string>> = {
  default: {
    default:
      'linear-gradient(180deg, rgba(138, 136, 255, 0.15) 0%, rgba(138, 136, 255, 0.12) 100%)',
    focused:
      'linear-gradient(180deg, rgba(138, 136, 255, 0.15) 0%, rgba(138, 136, 255, 0.12) 100%)',
    hovered:
      'linear-gradient(180deg, rgba(138, 136, 255, 0.35) 0%, rgba(138, 136, 255, 0.28) 100%)',
    pressed:
      'linear-gradient(180deg, rgba(138, 136, 255, 0.20) 0%, rgba(138, 136, 255, 0.25) 100%)',
    'inner-shadow': '',
    'inner-shadow-pressed': 'active:shadow-[0px_2px_1px_0px_#0E122F_inset]',
  },
  dark: {
    default:
      'linear-gradient(180deg, rgba(138, 136, 255, 0.45) 0%, rgba(138, 136, 255, 0.36) 100%), rgba(14, 18, 47, 0.80)',
    focused:
      'linear-gradient(180deg, rgba(138, 136, 255, 0.45) 0%, rgba(138, 136, 255, 0.36) 100%), rgba(14, 18, 47, 0.80)',
    hovered:
      'linear-gradient(180deg, rgba(138, 136, 255, 0.55) 0%, rgba(138, 136, 255, 0.44) 100%), rgba(14, 18, 47, 0.80)',
    pressed:
      'linear-gradient(180deg, rgba(138, 136, 255, 0.20) 0%, rgba(138, 136, 255, 0.25) 100%), rgba(14, 18, 47, 0.80)',
    'inner-shadow': 'shadow-[0px_1px_1px_0px_rgba(165,163,255,1)_inset]',
    'inner-shadow-pressed': 'active:shadow-[0px_2px_1px_0px_#1A1A4D_inset]',
  },
  light: {
    default:
      'linear-gradient(180deg, rgba(138, 136, 255, 0.22) 0%, rgba(138, 136, 255, 0.45) 100%), rgba(255, 255, 255, 0.80)',
    focused:
      'linear-gradient(180deg, rgba(138, 136, 255, 0.22) 0%, rgba(138, 136, 255, 0.45) 100%), rgba(255, 255, 255, 0.80)',
    hovered:
      'linear-gradient(180deg, rgba(138, 136, 255, 0.28) 0%, rgba(138, 136, 255, 0.55) 100%), rgba(255, 255, 255, 0.80)',
    pressed:
      'linear-gradient(180deg, rgba(138, 136, 255, 0.25) 0%, rgba(138, 136, 255, 0.13) 100%), rgba(255, 255, 255, 0.80)',
    'inner-shadow': 'shadow-[0px_1px_1px_0px_rgba(165,163,255,1)_inset]',
    'inner-shadow-pressed': 'active:shadow-[0px_2px_1px_0px_#1A1A4D_inset]',
  },
};

export const SecondaryButton: React.FC<SecondaryButtonProps> = (props) => {
  const variant = props.variant || 'default';
  const gradients = useMemo(() => {
    return secondaryButtonGradient[variant];
  }, [variant]);

  const bgGradientColor = useMemo(() => {
    switch (variant) {
      case 'dark':
        return 'bg-[radial-gradient(50.16%_50.16%_at_50.16%_49.84%,_#5EFCD6_0%,_#5E5BED_100%)]';
      default:
        return '';
    }
  }, [variant]);

  const hoverStyle = useMemo(() => {
    let hoverStyle;
    if (variant === 'dark' || variant === 'light') {
      hoverStyle =
        'hover:outline-coldsnap4 hover:outline-offset-2 active:outline-transparent hover:drop-shadow-[0_0_8px_rgba(94,252,214,1)] active:drop-shadow-[0_0_0_rgba(0,0,0,0)]';
    }
    if (props.isDanger && variant === 'dark') {
      hoverStyle = 'hover:outline-functional-red hover:outline-offset-2 active:outline-transparent';
    }
    return hoverStyle;
  }, [variant, props.isDanger]);

  const textElement = useMemo(() => {
    const dropshadow =
      'group-hover:drop-shadow-[0_0_5px_rgba(94,252,214,1)] group-active:drop-shadow-[0_0_0_rgba(0,0,0,0)]';
    return (
      <div
        className={`text-center ${dropshadow} ${
          props.isDanger &&
          'text-functional-red group-hover:drop-shadow-[0_0_5px_rgba(252,94,94,1)] group-active:drop-shadow-[0_0_0_rgba(0,0,0,0)]'
        }`}
      >
        {props.text}
      </div>
    );
  }, [props.text, props.isDanger]);

  return (
    <BaseButton
      {...props}
      textElement={textElement}
      gradients={gradients}
      btnType={`secondary-${variant}`}
      hoverStyle={hoverStyle}
      shadow={secondaryButtonGradient[variant]['inner-shadow']}
      shadowPressed={secondaryButtonGradient[variant]['inner-shadow-pressed']}
    >
      <div
        key='container'
        className='relative select-none transition-opacity opacity-100 active:opacity-80 rounded-lg overflow-hidden z-10] drop-shadow-[0_0_5px_rgba(94,252,214,1)'
      >
        <animated.div
          key='background'
          className={`absolute -inset-1 blur-sm pointer-events-none`}
        />
        {props.variant !== 'default' && (
          <animated.div
            key='_'
            className={`absolute z-0 rounded-[50%] ${
              props.isDanger
                ? 'bg-[radial-gradient(50.16%_50.16%_at_50.16%_49.84%,_#FC5E5E_0%,_rgba(252,_94,_94,_0.00)_100%)]'
                : bgGradientColor
            } inset-0 pointer-events-none top-[60%] h-full w-full blur-[8px] opacity-0 group-hover:opacity-50 group-active:opacity-0`}
          />
        )}
        <animated.div
          key='text_'
          className={`space-grotesk-regular rounded-lg ${
            variant === 'light'
              ? 'text-night7 group-hover:text-coldsnap4 group-active:text-night7'
              : 'text-night1 group-hover:text-coldsnap4  group-active:text-night1'
          }`}
        ></animated.div>
      </div>
    </BaseButton>
  );
};
