import { animated } from '@react-spring/web';
import React from 'react';
import { BaseButton, ButtonProps } from '../BaseButton';

export const AlarmButton: React.FC<ButtonProps> = (props) => {
  const alarmButtonGradients: Record<string, string> = {
    default:
      'radial-gradient(170.26% 142.00% at 50.00% -33.00%, #FF98C1 0%, #FF7292 30.73%, #FC5E5E 86.46%)',
    focused:
      'radial-gradient(170.26% 142.00% at 50.00% -33.00%, #FF98C1 0%, #FF7292 30.73%, #FC5E5E 86.46%)',
    hovered:
      'radial-gradient(119.9% 100% at 50% 0%, #FF98C1 0%, rgba(255, 114, 146, 0.99) 38.02%, #FC5E5E 100%)',
    pressed:
      'radial-gradient(223.02% 186.00% at 50.00% 186.00%, #FF98C1 0%, #FF7292 50%, #FC5E5E 100%)',
    'inner-shadow': 'shadow-[0px_2px_1px_0px_rgba(255,255,255,0.20)_inset]',
    'inner-shadow-pressed': 'active:shadow-[0px_2px_1px_0px_rgba(0,0,0,0.20)_inset]',
  };

  const textElement = (
    <div className='text-center [text-shadow:_0px_1px_1px_rgb(255_255_255_/_45%)]'>
      {props.text}
    </div>
  );

  return (
    <BaseButton
      {...props}
      textElement={textElement}
      gradients={alarmButtonGradients}
      btnType='alarm'
      shadow={alarmButtonGradients['inner-shadow']}
      shadowPressed={alarmButtonGradients['inner-shadow-pressed']}
    >
      <div
        key='container'
        className='relative select-none transition-opacity opacity-100 active:opacity-80 rounded-lg overflow-hidden z-10'
      >
        <animated.div
          key='background_boxShadow'
          className={`absolute -inset-1 blur-sm pointer-events-none`}
        />
        <animated.div
          key='text_'
          className={`relative z-10 space-grotesk-bold text-night9 transition-all ease-in-out duration-250`}
        ></animated.div>
      </div>
    </BaseButton>
  );
};
