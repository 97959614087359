import { useSpring } from '@react-spring/web';
import React, { useCallback, useMemo, useState } from 'react';
import { ButtonTypes } from './button.types';

export type ButtonBackgrounds = Record<string, string>;

interface ButtonPropsStyle {
  hoverStyle?: string;
  textColor?: Record<string, string>;
  gradients?: ButtonBackgrounds;
  btnType?: ButtonTypes;
  shadow?: string;
  shadowPressed?: string;
  textElement: React.ReactElement;
}
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  id?: string;
  text?: string;
  buttonType?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  isLoading?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  padding?:
    | 'none'
    | 'icon'
    | 'slimmer'
    | 'slim'
    | 'slim-wide'
    | 'normal'
    | 'medium'
    | 'thick'
    | 'thicker';
  overrides?: string;
  containerOverrides?: string;
  fontSize?: 'smaller' | 'small' | 'normal' | 'large';
  icon?: JSX.Element;
  animate?: boolean;
  children?: React.ReactElement;
  uppercase?: boolean;
  disableIconHover?: boolean;
}

export const BaseButton: React.FC<ButtonProps & ButtonPropsStyle> = ({
  id,
  disabled,
  isLoading,
  onClick,
  icon,
  text,
  textElement,
  padding = 'normal',
  overrides,
  containerOverrides,
  fontSize = 'normal',
  gradients,
  textColor,
  buttonType,
  btnType: type,
  hoverStyle,
  animate = true,
  shadow,
  shadowPressed,
  children,
  disableIconHover,
  uppercase = true,
  name,
  role,
}) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const [pressed, setPressed] = useState<boolean>(false);
  const [animations, setAnimations] = useSpring(() => ({
    background: gradients?.default,
    outline: '1px solid rgba(0,0,0,0.0)',
    outlineOffset: ' 0px',
    color: textColor?.default,
    immediate: !animate,
    config: {
      tension: 240,
      friction: 14,
      duration: 100,
    },
    pause: !animate,
  }));

  const classNames = useMemo(() => {
    const fontSizes = {
      smaller: 'text-sm',
      small: 'text-base',
      normal: 'text-lg',
      large: 'text-2xl',
    };
    const paddingSettings = {
      none: 'py-3 px-0',
      icon: 'py-2.5 px-2.5',
      slimmer: 'py-2 px-2',
      slim: 'py-2 px-3.5',
      'slim-wide': 'py-0.5 px-6',
      normal: 'px-10 py-3',
      medium: 'py-4.5 px-7',
      thick: 'px-12 py-5',
      thicker: 'px-12 py-6',
    };
    const classes: string[] = [];
    if (fontSize) classes.push(fontSizes[fontSize]);
    if (padding) classes.push(paddingSettings[padding]);
    classes.push('flex items-center justify-center');
    isLoading && classes.push('animate-pulse');
    if (overrides) classes.push(overrides);
    return classes.join(' ');
  }, [fontSize, isLoading, padding, overrides]);

  const TextSpacing = useCallback(() => <div className='opacity-0'>{text}</div>, [text]);

  const handleMouseEnter = () => {
    setHovered(true);
    if (!disabled) {
      setAnimations.start({
        background: gradients?.hovered,
        color: textColor?.hovered,
      });
    }
  };
  const handleMouseLeave = () => {
    setHovered(false);
    setAnimations.start({
      background: gradients?.default,
      color: textColor?.default,
    });
    setPressed(false);
  };
  const handleMouseDown = () => {
    setPressed(true);
    if (!disabled) {
      setAnimations.start({
        color: textColor?.pressed,
        background: gradients?.pressed,
      });
    }
  };
  const handleMouseUp = () => {
    setPressed(false);
    setAnimations.start({
      background: hovered ? gradients?.hovered : gradients?.default,
      color: hovered ? textColor?.hovered : textColor?.default,
      outline: '1px solid rgba(255,255,255,0.9)',
      outlineOffset: '1px',
    });
    setTimeout(() => {
      setAnimations.start({
        outline: '1px solid rgba(0,0,0,0)',
        outlineOffset: '2px',
      });
    }, 200);
  };

  const GradientElement = useMemo(() => {
    if (!children) return <></>;

    const childElements = children.props.children;
    const content: React.ReactElement = (
      <div className={`${classNames} relative z-10 rounded-lg h-full`}>
        <div className={`flex relative items-center justify-center space-x-4`}>
          <div
            className={`${
              hovered && !pressed && type !== 'secondary-default' && !disableIconHover
                ? 'drop-shadow-[0_0_5px_rgba(94,252,214,1)]'
                : ''
            }`}
          >
            <div
              className={` ${
                hovered && !pressed && type !== 'secondary-default'
                  ? 'drop-shadow-[0px_-2px_2px_rgba(0,0,0,0.35)]'
                  : 'drop-shadow-[0px_1px_2px_rgba(0,0,0,0.45)]'
              }`}
            >
              {icon &&
                React.cloneElement(icon, {
                  color: disableIconHover
                    ? icon.props.color
                    : hovered && !pressed
                      ? 'coldsnap4'
                      : icon.props.color,
                })}
            </div>
          </div>

          {text && text !== '' && (
            <div
              className={`${uppercase && 'uppercase'} rounded-lg ${
                icon ? '-translate-x-[0.5rem]' : ''
              }  transition-all ease-in-out duration-0`}
            >
              <div className={`relative inset-0 flex items-center justify-center`}>
                <TextSpacing />
              </div>
              <div
                className={` whitespace-nowrap absolute  inset-0 flex items-center justify-center rounded-lg ${
                  type?.startsWith('secondary')
                    ? hovered && !pressed && type !== 'secondary-default'
                      ? '[text-shadow:_0px_-1px_1px_rgb(0_0_0_/_25%)]'
                      : '[text-shadow:_0px_2px_2px_rgb(0_0_0_/_10%)]'
                    : ''
                } `}
              >
                {textElement}
              </div>
            </div>
          )}
        </div>
      </div>
    );

    const elementsWStyle = childElements
      .filter((element: any) => element)
      .map((element: any) => {
        const style: any = {};
        let hasText = false;
        const keys = element.key.split('_');
        for (const k of keys) {
          if (k === 'text') {
            hasText = true;
          } else if (k) {
            style.style = {
              ...style.style,
              [k]: animations[k as keyof typeof animations],
            };
          }
        }
        return React.cloneElement(element, style, hasText ? content : null);
      });
    return React.cloneElement(children, {}, [...elementsWStyle]);
  }, [
    children,
    classNames,
    hovered,
    pressed,
    type,
    animations,
    icon,
    disableIconHover,
    text,
    TextSpacing,
    uppercase,
    textElement,
  ]);

  return (
    <div className='relative w-full'>
      <button
        id={id}
        aria-label={name}
        role={role}
        tabIndex={0}
        disabled={disabled || isLoading}
        className={`
            group bg-transparent transition-all ease-in-out duration-250 outline-offset-2 outline outline-1 outline-transparent active:outline-offset-8 
            ${hoverStyle}
            ${shadow}
            ${containerOverrides}
            drop-shadow-[0_3px_2px_rgba(0,0,0,0.1)]
            rounded-0.5xl overflow-hidden transition-all ease-in-out duration-300 [&:focus-visible]:outline [&:focus-visible]:outline-[1px] [&:focus-visible]:outline-heatwave7 [&:focus-visible]:rounded-lg
            ${!disabled && shadowPressed} 
            ${
              disabled || isLoading ? 'w-full cursor-not-allowed opacity-60 outline-none' : 'w-full'
            }
            `}
        onClick={onClick}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        type={buttonType}
      >
        {GradientElement}
      </button>
    </div>
  );
};
