import { animated } from '@react-spring/web';
import React from 'react';
import { BaseButton, ButtonProps } from '../BaseButton';

export const PrimaryButton: React.FC<ButtonProps> = (props) => {
  const primaryButtonGradients: Record<string, string> = {
    default: 'radial-gradient(105.84% 185% at 50% -85%, #FFFFFF 0%, #5EFCD6 50%, #5E5BED 100%)',
    focused:
      'radial-gradient(105.84% 185.00% at 50.00% -85.00%, #FFF 0%, #5EFCD6 50%, #5E5BED 100%)',
    hovered:
      'radial-gradient(104.12% 182.00% at 50.00% -58.00%, #FFF 0%, #5EFCD6 50%, #5E5BED 100%)',
    pressed:
      'radial-gradient(97.26% 170.00% at 50.00% 170.00%, #FFF 0%, #5EFCD6 50%, #5E5BED 100%)',
    'inner-shadow': 'shadow-[0px_2px_1px_0px_rgba(255,255,255,1)_inset]',
    'inner-shadow-pressed': 'active:shadow-[0px_2px_1px_0px_rgba(0,0,0,1)_inset]',
  };

  const textElement = (
    <div className='text-center [text-shadow:_0px_1px_1px_rgb(255_255_255_/_45%)]'>
      {props.text}
    </div>
  );

  return (
    <BaseButton
      {...props}
      textElement={textElement}
      gradients={primaryButtonGradients}
      btnType='primary'
      shadow={primaryButtonGradients['inner-shadow']}
      shadowPressed={primaryButtonGradients['inner-shadow-pressed']}
    >
      <div
        key='container'
        className='relative select-none transition-opacity opacity-100 active:opacity-80 rounded-lg overflow-hidden z-10'
      >
        <animated.div
          key='background_boxShadow'
          className={`absolute -inset-1 blur-sm pointer-events-none`}
        />
        <animated.div
          key='text_'
          className='space-grotesk-bold text-night9 text-lg '
        ></animated.div>
      </div>
    </BaseButton>
  );
};
